.development-btn-wrap {
  padding-right: 130px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.develop__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 40px;
  padding-bottom: 70px;
}

@media (max-width: 1199px) {
  .list-des li {
    padding-left: 40px;
    font-size: 20px;
  }
}


@media (max-width: 991px) {
  .section-development {
    .description-text h3 {

      height: 60px;
    }
  }

  .list-des li {
    font-size: 16px;
    &::before{
      top: 1px;
    }
  }
}

@media (max-width: 767px) {
  .develop__row {
    grid-template-columns: 1fr;
  }
}