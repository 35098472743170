html {
  -webkit-text-size-adjust: 100%
}

html:focus-within {
  scroll-behavior: smooth
}

body {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  position: relative;
  text-rendering: optimizeSpeed;
  width: 100%
}

*,
:after,
:before {
  box-sizing: border-box
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline
}

:focus {
  outline: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

input,
input:required {
  box-shadow: none
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #fff
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none
}

input:focus {
  outline: none
}

audio,
canvas,
video {
  display: inline-block;
  max-width: 100%
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden] {
  display: none
}

a:active,
a:hover {
  outline: none
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle
}

img,
picture {
  display: inline-block
}

button,
input {
  line-height: normal
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  background: transparent;
  border: 0;
  cursor: pointer
}

button[disabled],
html input[disabled] {
  cursor: default
}

[disabled] {
  pointer-events: none
}

input[type=checkbox],
input[type=radio] {
  padding: 0
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

button {
  background: transparent;
  border: 0
}

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0
}

hr {
  background: #000;
  border: 0;
  box-sizing: content-box;
  height: 1px;
  line-height: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
  page-break-after: always;
  width: 100%
}

pre {
  font-family: monospace, monospace;
  font-size: 100%
}

a {
  background-color: transparent
}

abbr[title] {
  border-bottom: none;
  text-decoration: none
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace
}

small,
sub,
sup {
  font-size: 75%
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -5px
}

sup {
  top: -5px
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
  padding: 0
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  outline: 0;
  padding: 0
}

legend {
  border: 0;
  color: inherit;
  display: block;
  max-width: 100%;
  white-space: normal;
  width: 100%
}

fieldset {
  min-width: 0
}

body:not(:-moz-handler-blocked) fieldset {
  display: block
}

progress {
  vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

template {
  display: none
}