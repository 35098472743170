/////////////////// footer ///////////////

footer.footer {
  background: $color-dark;
  padding: 70px 0;
  color: #fff;

  .copyright {
    text-align: center;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  footer.footer {
    padding: 40px 0;

    .foot-menu {
      .content {
        grid-template-columns: auto;
        grid-gap: 25px;

        .location {
          text-align: center;
        }
      }
    }

    .mainBlock_social ul {
      display: flex;
      justify-content: center;
    }
  }

}