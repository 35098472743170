///////////////////////// portfolio //////////////////
.section-portfolio{
  background: $color-grey;
}

.portfolio-list {
  overflow: hidden;

  .swiper-slide {
    display: grid;
    position: relative;
    grid-template-rows: auto 1fr;
    grid-gap: 25px;
    padding: 30px 35px;
    height: initial;
    transition: all .3s;
    background: #fff;
    &:hover {
      border: 0;
      box-shadow: none;
    }

    .client-logo {
      position: relative;
      width: 100%;
      height: 40px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 5px;
      font-size: 18px;
    }

    p.title {
      font: 700 18px/1.2 'Poppins';
      color: #fff;

      &.dark {
        color: $color-dark;
      }
    }

    h4 {
      margin-bottom: 10px;
      font: 700 12px/140% 'Poppins';
      letter-spacing: 2.2px;
      text-transform: uppercase;
      transition: all .3s;
      position: relative;

      & + p {
        font-size: 14px;
        margin-bottom: 20px;
      }
      span{
        letter-spacing: normal;
        font: 400 14px/1.5 'Poppins';
        text-transform: none;
      }
    }
    .link-card{
      color: $color-red;
      transition: all .3s;
      &:hover{
        text-decoration: none;
      }
    }

    &.dark {
      background: $color-dark;
      color: #fff
    }

    &.red {
      background: $color-red;
      color: #fff;
      .link-card{
        color: $color-dark;
      }
    }
  }
}
@media (max-width: 767px) {
  .portfolio-list .swiper-slide h4+p {
    font-size: 13px;
  }
}


@media (max-width: 480px) {
  .portfolio-list .swiper-slide {
    padding: 15px 18px;
  }
}
