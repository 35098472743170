//////////////////////////// slider benefits /////////////////////////

.section-benefits {
  .description {
    flex-direction: row;
    display: flex;
  }
}

.swiper-develop-wrap {
  max-width: 1041px;
  position: relative;

  .swiper-develop-container {
    overflow-x: clip;
    overflow-y: visible;
    z-index: 2;

    .swiper-slide {
      transform: scale(0.7);

      &.swiper-slide-active {
        transform: scale(1);
        z-index: 111;

        .item-develop {
          transform: translateX(-16%);
        }
      }

      &.swiper-slide-next, &.swiper-slide-prev{
        transform: scale(0.8);
        z-index: 2;
      }
    }
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: -25px;
  }
}

.swiper-develop-wrap .swiper-develop-container .item-develop {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 15px;
  padding: 40px 45px;
  transition: 0.7s all;
  background: #fff;
  width: 390px;
  height: 527px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  cursor: grab;
  & h3 {
    max-width: 220px;
  }
}



.swiper-develop-wrap .swiper-develop-container .swiper-slide.swiper-slide-active {
  transform: scale(1);
  z-index: 111;
}


@media (max-width: 1199px) {
  .swiper-develop-wrap {
    .swiper-develop-container {
      .item-develop{
        height: 440px;
      }
    }
  }
}

@media (max-width: 767px) {
  .swiper-develop-wrap .swiper-develop-container .item-develop {
    padding: 23px 10px;
    gap: 8px;
    width: auto;
    height: auto;
  }
  .swiper-develop-wrap .swiper-develop-container .swiper-slide.swiper-slide-active .item-develop {
    transform: translateX(0);
  }
  .swiper-develop-wrap .swiper-develop-container .item-develop h3 {
    max-width: 100%;
    width: 100%;
    font-size: 18px;
    text-align: center;
}
}
