////////////////////// section form ////////////

.section-form {
  background: #fff;

  h2 {
    font: 300 56px/80px 'Poppins', sans-serif;
  }

  .contact-form {
    margin-top: 40px;
  }
}

.contact-form {
  .btn-red {
    margin: 0 auto;
    width: 100%;
    max-width: 520px;
  }

  .input_block_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
  }

  .input_block {
    margin-bottom: 40px;
  }

  .control--checkbox {
    position: relative;
    display: inline-flex;
  }

  .checkbox-text {
    font-size: 14px;
    line-height: 200%;
    color: $color-dark;

    a {
      text-decoration: underline;
      font-weight: 700;
      font-family: 'Poppins';
      color: $color-dark;
    }
  }

  label.error {
    color: $color-red;
    font-size: 12px;
  }

  label.valid {
    color: green;
    font-size: 12px;
  }

  .wr-box {
    padding: 50px 0 20px;

    #terms-error {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .section-form {
    h2 {
      font: 300 42px/49px "Poppins", sans-serif;
    }
  }

  .contact-form {
    .input_block_wrap {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }

    h2 {
      font: 300 37px/65px "Poppins", sans-serif;
    }

  }
}

@media (max-width: 480px) {
  .section-form {
    h2 {
      font: 300 30px/42px "Poppins", sans-serif;
      padding-bottom: 20px;
    }
  }

  .contact-form {
    h2 {
      font: 300 30px/45px "Poppins", sans-serif;
    }
  }
}