.cooperation__row {
  padding-left: 131px;
  color: #fff;
}

.spoiler {
  position: relative;
  margin: 10px 0;
  width: 100%;

  .head {
    display: flex;
    font: 700 24px/140% 'Poppins';
    z-index: 3;
    align-items: baseline;
    color: #FFF;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 16px 46px 16px 28px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "+";
      transform: unset;
      color: white;
      left: 0;
      top: unset;
      line-height: unset;
      right: unset;
      background: unset;
      width: 16px;
      height: 16px;
      transition: .3s all;
      position: absolute;
    }

    &:hover {
      color: $color-red;

      &::before {
        color: $color-red;
      }
    }
  }

  .cont {
    background: transparent;
    display: none;
    padding: 20px 20px 20px 131px;
    width: 100%;
  }

  .descr {
    position: relative;
    font: 400 22px 'Poppins';
    max-width: 777px;
  }
}



@media (max-width: 1199px) {
  .cooperation__row {
    padding-left: 93px;
  }

  .spoiler {
    .head {
      padding: 16px 46px 16px 32px;
      font-size: 20px;

    }

    .descr {
      font-size: 20px;
    }

    .cont {
      padding: 20px 20px 20px 92px;
    }
  }
}

@media (max-width: 991px) {
  .cooperation__row {
    padding-left: 0;
  }

  .spoiler {
    .head {
      font-size: 18px;

    }

    .descr {
      font-size: 18px;
    }

    .cont {
      padding: 20px 20px 20px 80px;
    }
  }
}

@media (max-width: 767px) {
  .spoiler {
    .cont {
      padding: 20px 0;
    }
  }

  .section {
    &.section-process {
      padding-bottom: 70px;
    }
  }

}