//////////// first screen ///////////////

.section-intro {
  height: 100vh;
  .section__wrapper {
    position: relative;
    z-index: 2;
    color: #fff;

    .section__title-block {
      padding-top: 150px;
    }

    .section__title {
      font-weight: 300;
      font-family: 'Poppins';
      line-height: 125%;
    }

    .section__description {
      font-size: 22px;
      line-height: 160%;
      text-transform: none;
    }
  }

  .wr-btn {
    margin-top: 60px;
  }
}

// block animation
.bg-first-screen {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  #gradient-canvas {
    display: flex;
    width: 100%;
    height: 100%;
    --gradient-color-1: #1e1e23;
    --gradient-color-2: #fc5959;
    --gradient-color-3: #1e1e23;
    --gradient-color-4: #fc5959;
  }
}