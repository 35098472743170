///////////////////// section-discovery ///////////

.section-discovery {
  .content {
    padding-left: 130px;
    padding-right: 0;
    padding-top: 27px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 133px;
    grid-row-gap: 84px;
  }

  .item {
    position: relative;

    p {
      font-size: 22px;
      line-height: 160%;
      position: relative;
    }

    h3 {
      letter-spacing: .3em;
      font: 700 24px/140% "Poppins";
      text-transform: uppercase;
      color: $color-red;
      margin-bottom: 20px;
      position: relative;
    }
  }
}

@media (max-width: 1199px) {

  .section-discovery {
    .content {
      padding-left: 0;
      padding-right: 6px;
      grid-column-gap: 9px;
      grid-row-gap: 70px;
    }

    .item {
      p {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .section-discovery {
    .content {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 767px) {
  .section-discovery {
    .content {
      grid-template-columns: 1fr;
    }

    .item p {
      font-size: 18px;
    }
  }
}