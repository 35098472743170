//section-technologies

.section-technologies {
  padding-left: 0;
  padding-right: 0;
}

.wr-tech-box {
  display: grid;
  grid-template-columns: 1fr 1fr;

}

@media (max-width: 767px) {
  .description-text h3 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .wr-tech-box {
    grid-template-columns: 1fr;
  
  }
}