body {
  font-family: "Poppins";
  font-weight: 400;
  color: rgba(30, 30, 35);
  overflow-x: hidden;
  line-height: 1.5;
  &.no-scroll {
    overflow: hidden;
}
}

.section {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 70px 10px;

  &__wrapper {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
  }

  &__title-block {
    padding-bottom: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    text-transform: uppercase;
    font-size: 70px;
  }

  &__description {
    font-size: 30px;
  }

  &__title {
    font-size: 70px;
  }

  // color section
  &.section-development {
    padding-bottom: 120px;
    background: $color-grey;
  }

  &.bg-section {
    background: #fff;
  }

  &.section-process {
    background: $color-dark;
    color: #fff;
    padding-bottom: 200px;

    .guides__block-list {
      li {
        background: rgba(80, 80, 83, .4)
      }
    }
  }

  &.section-technologies {
    overflow: visible;
    padding-top: 0;
    background: #fff;
  }

  &.section-discovery {
    background: $color-dark;
    color: #fff;
  }
}

.guides__block {
  margin: 0 auto;
  max-width: 1041px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.guides__block-list {
  position: absolute;
  top: 0;
  max-width: 1041px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: -1;

  li {
    width: 1px;
    height: 100%;
    background: rgba(80, 80, 83, 0.17);
  }
}

.description-sec {
  padding: 60px 0 86px;
  font-size: 22px;
  line-height: 160%;
  color: rgba(30, 30, 35, .43);
  max-width: 784px;
  margin: auto;
}

.list-des {
  display: grid;
  grid-gap: 30px;

  li {
    position: relative;
    padding-left: 60px;
    font-size: 22px;
    line-height: 140%;
    font-weight: 400;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      background: $color-red;
      top: 5px;
    }
  }

}

.btn {
  text-decoration: none;
  padding: 18px 40px;
  text-align: center;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  opacity: 1;
  line-height: 140%;
  letter-spacing: .3em;
  text-transform: uppercase;

  &.btn-black {
    background: #1e1e23;
    color: #fff;
  }

  &.btn-white {
    background: #fff;
    color: #1e1e23;
  }

  &.btn-red {
    background: $color-red;
    color: #fff;
  }
}

.description-text {
  padding: 45px 0 0 0;

  h3 {
    font: 700 24px/140% 'Poppins';
    letter-spacing: 4.2px;
    text-transform: uppercase;
    color: #1e1e23;
    transition: all .3s;
    position: relative;
    margin-bottom: 20px;
    display: inline-block;
  }

  p {
    max-width: 390px;
    font-size: 22px;
    line-height: 160%;
    color: #1e1e23;

    span {
      color: $color-red;
    }
  }
}

.vac-block {
  width: 100%;
  margin: 0 auto;
  max-width: 1041px;
  background: $color-red;
  color: #fff;
  position: relative;
  transform: translateY(-50%);
  padding: 20px 40px;
  z-index: 9;

  p {
    font-size: 22px;
    line-height: normal;
    margin-bottom: 20px;
  }

  .foot {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;

    h3 {
      text-transform: uppercase;
      font-size: 32px;
      margin-bottom: 6px;
      line-height: 120%;
    }
  }
}

////////////// form common style ////////////
input[type=text],
input[type=email],
input[type=tel],
textarea {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 2px solid #e4e4e4;
  font: 400 22px "Poppins";
  color: $color-dark;
}

input[type=text],
input[type=email],
input[type=tel] {
  height: 50px;
}

textarea {
  height: 90px;
  cursor: initial;
  outline: none;
}

.wr-checkbox {
  input[type=checkbox] {
    margin-right: 15px;
    position: relative;
    visibility: hidden;

    &:checked {
      & + span {
        &:before {
          background: #fc5959;
          border: 1px solid #fc5959
        }

        &:after {
          visibility: visible
        }
      }
    }

    & + span {
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        width: 20px;
        top: 2px;
        left: 0;
        background: rgba(214, 214, 214, .4);
        border: 1px solid #d6d6d6;
        box-sizing: border-box;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 7px;
        width: 6px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        visibility: hidden;
      }
    }
  }
}

@media (max-width: 1199px) {

  .guides__block,
  .guides__block-list {
    max-width: 728px;
  }

  .section__title {
    font-size: 60px;
  }

  .section__description {
    font-size: 26px;
  }

  .description-sec {
    padding: 0 0 36px 79px;
    font-size: 20px;
  }

  .description-text p {
    font-size: 20px;
  }

  .list-des {
    grid-gap: 20px;
  }

  .vac-block .foot h3 {
    font-size: 28px;
  }
}




@media (max-width: 991px) {

  .guides__block,
  .guides__block-list {
    max-width: 628px;
  }

  .development .description-text p,
  .development .description-text ul li {
    font-size: 20px;
  }
}

@media (max-width: 767px) {

  .guides__block,
  .guides__block-list {
    max-width: 428px;
    width: 100%;
  }

  .section__title {
    font-size: 37px;
  }

  .section__description {
    font-size: 22px;
  }

  .description-sec {
    padding: 0 0 36px 0;
    font-size: 16px;
  }

  .description-text p {
    font-size: 16px;
  }

  .vac-block {
    transform: none;
    margin-bottom: 70px;

    .foot {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }
}

@media (max-width: 480px) {

  .guides__block,
  .guides__block-list {
    max-width: 400px;
    width: 100%;
  }

  .guides__block-list li:nth-child(n+5) {
    display: none;
  }

  input[type=text],
  input[type=email],
  input[type=tel],
  textarea {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .guides__block,
  .guides__block-list {
    max-width: 355px;
  }
}





@media (max-width: 1199px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {}