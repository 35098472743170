////////////// header //////////////

header.header {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  top: 0;
  width: 100%;
  height: 100px;
  transition: all .3s;
  padding: 0 10px;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e7e7e8;
    height: 55px;

    .b-bun {
      background: $color-dark;
    }
  }

  .section__wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    .guides__block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

}

a.logo {
  position: relative;
  z-index: 9999999;
  display: inline-flex;
}

////////////////////////// main menu //////////////////////

//humburger
.b-menu {
  top: 2px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  transition: all .3s ease;
  user-select: none;
  z-index: 122;

  &.open-nav {
    .b-bun {
      background: $color-dark;
    }

    .b-bun--top {
      top: 12px;
      transform: rotate(45deg);
    }

    .b-bun--mid {
      opacity: 0;
    }

    .b-bun--bottom {
      top: 6px;
      transform: rotate(-45deg);
    }
  }
}

.b-bun {
  background: #fff;
  position: relative;
  transition: all .3s ease;
}

.b-bun--top {
  height: 3px;
  top: 0;
  width: 30px;
}

.b-bun--mid {
  height: 3px;
  top: 8px;
  width: 21px;
}

.b-bun--bottom {
  height: 3px;
  top: 16px;
  width: 30px;
}


.fs-menu {
  position: fixed;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr auto;
  height: 100%;
  top: -100%;
  left: 0;
  width: 100%;
  background: #f7f7f9;
  overflow: hidden;
  z-index: 99;
  transition: .3s all;
  padding-top: 100px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;

  &.open-nav {
    top: 0;
  }

  .content-menu {
    margin: 0 auto;
    max-width: 1041px;
    width: 100%;

    li {
      font-size: 0;
      padding: 10px 0;
    }

    a {
      font: 400 24px 'Poppins', sans-serif;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-dark;
      transition: all .3s;

      &:hover {
        color: $color-red;
      }
    }
  }

  .guides__block {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    z-index: -1;

    .guides__block-list li {
      background: #e7e7e8;
    }
  }


}

.mainBlock_social {
  display: block;
  position: relative;

  ul {
    display: flex;

    li:not(:last-child) {
      padding-right: 30px;
    }
  }
}

.foot-menu {
  .content {
    margin: 0 auto;
    max-width: 1041px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    align-items: center;
    grid-gap: 60px;

    .location {
      text-align: left;
      font-weight: 400;
      font-size: 14px;

      a {
        display: block;
        text-transform: uppercase;
        color: $color-red;
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1199px) {
  .fs-menu {

    .content-menu,
    .foot-menu .content {
      max-width: 728px;
      margin: 0 auto;
    }
  }
}


@media (max-width: 991px) {
  .fs-menu {

    .content-menu,
    .foot-menu .content {
      max-width: 628px;
    }

    .foot-menu {
      .content {
        grid-gap: 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .fs-menu {
    .content-menu {
      max-width: 428px;
    }

    .foot-menu {
      display: none;
    }
  }
}