.fullscreen-modal {
  background: #fff;
  position: fixed;
  width: 100%;
  padding: 0;
  z-index: 9999999999999;
  transform-origin: 50% 0;
  perspective-origin: 50% 100%;
  transition: transform .3s ease;
  height: 100%;
  overflow: hidden;
  top: 0;
  transform: perspective(900px) rotateX(-90deg);

  &.active {
    visibility: visible;
    transform: perspective(900px) rotateX(0);
    opacity: 1;
    height: 100%;
  }

  .content {
    position: relative;
    display: grid;
    max-width: 800px;
    padding: 27px 0 40px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    grid-template-rows: auto 1fr;
  }

  .title {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
  }

  h4 {
    font-size: 32px;
    line-height: 48px;
    padding-bottom: 20px;
  }

  h2 {
    font: 700 42px 'Poppins';
    display: block;
    text-align: center;
    text-transform: uppercase;
  }

  p.description {
    font-size: 22px;
    line-height: 36px;
    padding: 30px 0 50px;
    display: block;
    width: 100%;
    max-width: 780px;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 0;
    width: 24px;
    height: 24px;

    &:hover svg path {
      opacity: .7;
    }

    svg {
      width: 24px;
      height: 24px;
      display: block;

      path {
        fill: #000;
        opacity: .4;
        transition: .3s all;
      }
    }
  }

  .cont {
    display: grid;
    align-items: center;
  }
}

.notification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(237, 237, 237);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 2px solid #1e1e23;
  width: 370px;
  display: none;
}

.notification-popup p {
  margin: 0;
  font-size: 16px;
  text-align: center;
  color: #1e1e23;
}

@media (max-width: 767px) {
  .fullscreen-modal {
    h4 {
      display: none;
    }

    h2 {
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      letter-spacing: 2px;
    }

    p.description {
      font-size: 18px;
      line-height: 26px;
      padding: 20px 0 20px;
    }
  }
}

@media (max-width: 480px) {
  .fullscreen-modal {
    .content {
      padding-top: 58px;
    }
  }
}

@media (max-width: 375px) {
  .fullscreen-modal {
    .content {
      padding-top: 58px;
    }
  }
}